.designer-page .content-block {
    width: 100%;
    max-width: 1200px;
}
.content-block .designer-title {
    font-size: 15px;
}

.content-block .designer-name {
    font-size: 35px;
    text-transform: none;
    text-decoration: underline;
    line-height: 35px;
    font-weight: normal;
    margin-top: 50px;
    margin-bottom: 50px;
}

.designer-description {
    max-width: 800px;
    padding: 0 15px;
    margin: auto;
    text-align: justify;
}

.designer-products {
    min-height: 0;
}

@media only screen and (max-width: 768px) {
    .content-block .designer-name {
        padding: 0 10px;
        margin-top: 5px;
        margin-bottom: 15px;
        text-decoration: none;
        font-size: 21px;
        color: #666666;
    }

    .designer-description {
        padding: 0 15px;
        text-align: justify;
    }
}

.submit-button {
    -webkit-appearance: none;
    background-color: #FF5A09;
    border: 1px solid #FF5A09;
    border-radius: 4px;
    padding: 5px 15px;
    margin: 5px;
    color:white;

    &.float-right {
        float: right;
    }

    &:hover {
        background-color: white;
        color: black;
        transition: background-color 0.3s ease-in, color 0.3s ease-in;
    }
}

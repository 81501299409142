.designers-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 940px;
    justify-content: center;
}

.designers-list a {
    text-decoration: none !important;
    color: #8f6f31 !important;
}

.designers-list div:hover {
    box-shadow: #8f6f31 1px 1px 8px 3px;
}

.designers-list div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media only screen and (max-width: 767px) {
    .designers-list a {
        width: calc(50% - 10px);
        margin: 5px;
    }
    .designers-list a div {
        width: 100%;
        margin: 0;
    }
}

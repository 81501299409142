@media only screen and (max-width: 767px) {
    .zgao-modal {
        & > div {
            // The backdrop
            & > div {
                // the modal content container
                & > div {
                    max-width: 100%;
                    position: fixed;
                    top: 15px;
                    bottom: 15px;
                    left: 15px;
                    max-height: 100%;
                    overflow: scroll;
                }
            }
        }
    }
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
    font-size: 18px;
    padding-top: 10px;

    button {
        -webkit-appearance: none;
        border: 0 solid transparent;
        background: transparent;
    }
}

.pagination {
    i.fa {
        font-size: 36px;
        line-height: 26px;
    }
    button {
        padding: 0 10px;
    }
}

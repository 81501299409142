.disclaimer a {
    color: white !important;
}

@keyframes bounce {
    0%,
    20%,
    60%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    80% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

.disclaimer a.insta-link:hover i {
    color: #cf309c !important;

    animation: bounce 1s;
}

.disclaimer-container {
    background-color: #666666;
    color: white;
    padding: 50px 0;
    text-align: center;
    width: 100%;
    margin: 0;
}

.disclaimer {
    width: 100%;
    max-width: 1200px;
}

@media only screen and (min-width: 951px) {
    .disclaimer-container {
        display: flex;
        justify-content: center;
    }
    .disclaimer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
    }
    .disclaimer .content-block {
        margin: 0;
        padding: 5px;
    }
    .disclaimer .content-block:first-child {
        text-align: left;
        width: 50%;
        min-width: 400px;
        padding-left: 10px;
    }
    .disclaimer .content-block:last-child {
        text-align: left;
        width: 50%;
        display: flex;
        justify-content: center;
    }
    .disclaimer-item img {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 950px) {
    .disclaimer-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-content: center;
        justify-content: center;
    }

    .disclaimer {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .disclaimer-item:first-child {
        flex-direction: column;
    }

    .disclaimer .content-block {
        display: flex;

        justify-content: center;
    }

    .disclaimer-item img {
        width: 100%;
        max-width: 120px;
        padding: 0 0 15px 0;
        margin: 0 !important;
    }

    .address-section {
        padding: 0 0 15px 0;
    }

    .instagram-container {
        display: flex;
        flex-direction: column;
        padding: 0 0 15px 0;
    }
}

.recently-viewed-container {
    width: 100%;
    max-width: 1200px;
}

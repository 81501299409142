#menu-wrapper {
    margin: 0;
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
}

/* Reset */
.nav,
.nav a,
.nav ul,
.nav li,
.nav div,
.nav form,
.nav input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

.nav a {
    text-decoration: none;
}

.nav li {
    list-style: none;
}

.nav li p {
    margin-bottom: 0;
}

/* Menu Container */
.nav {
    display: flex;
    position: relative;
    cursor: default;
    z-index: 500;
    margin-top: 0;
    width: 100%;
    justify-content: space-between;
}

/* Menu List */
.nav > li {
    display: block;
    float: left;
    color: #666666;
    text-transform: uppercase;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 19px;
    cursor: pointer;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.35);
    /*position: relative;*/
}

li.stripe {
    border-right: 2px solid #8f6f31;
}

/*!* effect *!*/
/*.nav > li:nth-child(3):after,*/
/*.nav > li:nth-child(4):after,*/
/*.nav > li:nth-child(5):after,*/
/*.nav > li:nth-child(6):after,*/
/*.nav > li:nth-child(7):after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    left: -2px;*/
/*    display: block;*/
/*    background-color: white;*/
/*    width: calc(100% + 4px);*/
/*    border-left: 2px solid #8f6f31;*/
/*    border-right: 2px solid #8f6f31;*/
/*    opacity: 0;*/

/*    -webkit-transition: all 0.15s ease 0.15s;*/
/*    -moz-transition: all 0.15s ease 0.15s;*/
/*    -o-transition: all 0.15s ease 0.15s;*/
/*    -ms-transition: all 0.15s ease 0.15s;*/
/*    transition: all 0.15s ease 0.15s;*/
/*}*/
/*!* effect *!*/
/*.nav > li:nth-child(3):hover:after,*/
/*.nav > li:nth-child(4):hover:after,*/
/*.nav > li:nth-child(5):hover:after,*/
/*.nav > li:nth-child(6):hover:after,*/
/*.nav > li:nth-child(7):hover:after {*/
/*    visibility: visible;*/
/*    height: 14px;*/
/*    opacity: 1;*/
/*}*/

.nav > li:last-child {
    border-right: 0 solid #8f6f31;
}

/* Menu Links */
.nav > li > a {
    position: relative;
    display: block;
    z-index: 510;
    color: #666666;
    text-transform: uppercase;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 18px;
    height: 32px;
    line-height: 32px;
    font-weight: 300;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.35);
    cursor: pointer;

    /*border-right: 1px solid #8f6f31;*/
    /*border-left: 1px solid #8f6f31;*/
    /*border-top: 2px solid #ffffff;*/
    /*border-bottom: 2px solid #ffffff;*/

    /*-webkit-transition: all 0.2s ease;*/
    /*-moz-transition: all 0.2s ease;*/
    /*-o-transition: all 0.2s ease;*/
    /*-ms-transition: all 0.2s ease;*/
    /*transition: all 0.2s ease;*/
}

.nav > li:hover {
    color: #8f6f31 !important;
}
.nav > li:hover > a,
.nav > li > a:hover {
    color: #8f6f31 !important;
    border-bottom: 2px solid #ffffff;
}

.nav > li:first-child > a {
    padding-left: 0;
    border-left: none;
}

.nav > li:last-child > a {
    padding-right: 0;
    border-left: none;
}

/* Menu Dropdown */
.nav > li > div {
    position: absolute;
    display: block;
    max-width: 810px;
    width: 100%;
    top: 44px;
    left: 0;

    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    background: white;
    border: 2px solid #8f6f31;
    /*border-radius: 0 0 3px 3px;*/

    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    -webkit-transition: all 0.15s ease 0.15s;
    -moz-transition: all 0.15s ease 0.15s;
    -o-transition: all 0.15s ease 0.15s;
    -ms-transition: all 0.15s ease 0.15s;
    transition: all 0.15s ease 0.15s;
}

.nav > li:hover > div {
    opacity: 1;
    visibility: visible;
    overflow: visible;
}

/* Menu Content Styles */
.nav .nav-column {
    float: left;
    width: 60%;
    padding: 2.5%;
}

.nav .nav-column h3 {
    margin: 20px 0 10px 0;
    line-height: 18px;

    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #372f2b;
    text-transform: uppercase;
}

.nav .nav-column h3.orange {
    color: #ff722b;
}

.nav .nav-column li a {
    display: block;
    line-height: 26px;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: normal;
    font-size: 16px;
    color: #888888;
    cursor: pointer;
    padding: 5px 0;
    text-transform: none;
}

.nav .nav-column p {
    font-style: italic;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: normal;
    font-size: 16px;
    color: #888888;
    text-transform: none;
}

.nav .nav-column li a:hover {
    color: #666666;
}

ul li .nav-column-preview-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 15px;
    min-height: 220px;
}

ul li .nav-column-preview-images div > div {
    height: 75px;
    width: 110px;
    background-color: #c7c7c7;
    margin: 5px;
}

.nav-column ul:last-child li:last-child a {
    font-weight: bold;
    font-size: 20px;
    color: black;
    text-transform: uppercase;
    position: absolute;
    bottom: 12px;
}

.bread-crumb {
    a {
        color: white !important;
        background-color: #666666;
        padding: 5px;
        border: 1px solid #666666;
        font-weight: normal !important;
    }

    span:last-child {
        a {
            color: #666666 !important;
            background-color: white;
            padding: 5px;
        }
    }
}

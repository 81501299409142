// All Mobile styling
// This is an extension from the desktop styling
@media only screen and (max-width: 767px) {
    .product-page {
        h1 {
            font-size: 19px;
            padding: 0 20px;

            .product-name-title {
                width: 100%;
                padding: 0;
            }
        }

        .heart-button-container {
            top: 0;
            right: -10px;
            position: relative;
        }
        svg#heart-svg {
            width: 48px;
        }
    }

    .image-gallery-slide-wrapper.right {
        margin-right: 0 !important;
    }

    .product-gallery-container {
        // Product Price information
        .product-price-container {
            width: 100%;
            padding: 10px;
            margin-top: 25px;

            .price-card {
                width: 100%;
                padding: 0;
                max-height: 35px;
                color: #666666;
                background-color: white;
                border: none;

                .mobile-price-card {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                }

                // only for sale items
                .old-price {
                    position: unset;
                    text-decoration: line-through;
                }

                // For sold items only
                .sold-out-price-card {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 700;
                    padding-top: 0;
                }
            }

            .purchase {
                width: 100%;
                min-height: 50px;
                height: unset;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: 19px;

                &.desktop-only {
                    display: none;
                }
            }

            .request-shipping {
                max-height: 50px;
                width: 100%;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 19px;
            }

            .free-shipping {
                max-height: 50px;
                width: 100%;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 19px;
            }

            .contact-question {
                width: 100%;
                text-transform: uppercase;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-size: 19px;
                max-height: 115px;
                text-align: center;

                &.sold-out {
                    max-height: 140px;
                }

                span {
                    &.mobile-only {
                        width: 100%;
                        position: unset;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    }
                }
                .contact-buttons {
                    width: 100%;
                    position: unset;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        padding: 10px;
                    }
                }
            }
        }
    }

    // Product specs and designer info
    .product-detail-container {
        width: 100%;
        flex-direction: column-reverse;
        border: 0;

        .item-story {
            width: 100%;
            p {
                //font-weight: bold;
            }
        }

        .item-details {
            width: 100%;
        }
    }

    .mobile-form {
    }
}

.tablet-only {
    display: none;
}

// Tablet specific styling for Product page
@media only screen and (min-width: 768px) and (max-width: 1250px) {
    .product-page {
        .slide-container {
            display: none;
        }
    }

    .tablet-only {
        display: block;
        max-width: 800px;
        margin: auto;
    }

    .product-price-container {
        display: flex;
        width: 100%;
        max-width: calc(100% - 45px);
        margin: 35px auto 20px auto;
        flex-direction: row;

        .contact-question {
            width: 100%;
            height: 125px;
            margin-top: 0;
            margin-left: 10px;

            .contact-buttons {
                bottom: 6px;
            }

            &.is-on-request {
                height: 125px;
            }
        }

        .request-shipping {
            height: 125px;
            margin-top: 0;
            margin-left: 10px;
            min-width: 135px;
        }

        .free-shipping {
            height: 125px;
            margin-top: 0;
            margin-left: 10px;
            min-width: 135px;
        }

        .purchase {
            height: 125px;
            margin-top: 0;
            margin-left: 10px;
            min-width: 135px;
        }

        .price-card {
            min-width: 110px;
        }
    }

    .product-detail-container {
        max-width: calc(100% - 45px);
        margin: auto;
        width: 100%;
    }
}

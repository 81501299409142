/*The backdrop*/
.zgao-modal > div > div {
    /*background-color: red;*/
    overflow: hidden;
}

/*Container with styles*/
.zgao-modal > div > div > div {
    border-radius: 0;
}

.zgao-modal .modal-content {
    background-color: white;
    border-radius: 0;
    border: 0;
    min-height: 500px;
}

.zgao-modal .modal-content {
    border-radius: 0;
}

.zgao-modal .modal-content header {
    background-color: #8f6f31;
    font-weight: bold;
    color: white;
    text-align: center;
    font-size: 1.25rem;
}

.zgao-modal .modal-content section {
    padding: 20px;
}

.zgao-modal * {
    border-radius: 0 !important;
}

.zgao-modal textarea {
    width: 100%;
    resize: none;
    -webkit-appearance: none;
    background-color: white;
    border: 1px solid #8f6f31;
    border-radius: 4px;
    padding: 5px 10px;
}

.zgao-modal h5 {
    margin-top: 20px;
    color: #8f6f31;
    font-weight: bold;
}
.zgao-modal h3 {
    color: #666666;
    font-size: 1.25rem;
}

.zgao-modal h5 .fa-caret-right {
    padding-right: 8px;
    color: #8f6f31;
}

.storage-address-footer {
    display: block;
}

.storage-address-footer p {
    padding: 0;
    margin: 0;
    font-weight: normal;
    color: #212529;
}

.contact-question.modal-version {
    border: 0;
    width: auto;
    height: auto;
}

.contact-question.modal-version .contact-buttons {
    right: 15px;
    left: auto;
}
.contact-question.modal-version .fa-envelope {
    display: none;
}

.zgao-modal .no-min-height {
    min-height: 0;
}

.zgao-modal .no-min-height .contact-question .contact-buttons {
    position: initial;
}

.page-content.small-margin-top {
    margin-top: 10px;
}

.cservice-container {
    display: flex;
}

.cservice-list div {
    width: 215px;
    height: 35px;
    color: #8f6f31;
    border: 2px solid #8f6f31;
    font-weight: bold;
    padding: 3px 10px;
    margin: 10px;
    font-size: 18px;
}
.cservice-list div:hover {
    cursor: pointer;
}

.cservice-list .active {
    color: white;
    background-color: #8f6f31;
}

.cservice-list i {
    font-size: 24px;
    vertical-align: text-top;
    letter-spacing: 8px;
}

.left-block {
    max-width: 475px;
    height: 475px;
    border: 2px solid #8f6f31;
    padding: 5px 20px 20px 20px;
    margin: 10px;
    overflow: hidden;
    overflow-y: auto;
}

.left-block p {
    font-weight: 900;
    margin-top: 1rem;
}

.left-block p:last-child {
    padding-top: 10px;
}

.right-block {
    max-width: 475px;
    height: 475px;
    margin: 10px;
}

@media only screen and (max-width: 1050px) {
    .right-block {
        display: none;
    }
    .left-block {
        width: 100%;
        max-width: 100%;
    }
    .cservice-container {
        width: 100%;
    }
    .content-block-cservice {
        width: 100%;
    }
}

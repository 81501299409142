.bread-crumb a {
    color: #666666 !important;
    font-size: 15px;
    line-height: 15px;
    font-weight: bold;
}

.bread-crumb span {
    color: #666666 !important;
    font-size: 15px;
    line-height: 15px;
    font-weight: bold;
}

.bread-crumb {
    margin-bottom: 15px;
    margin-top: 10px;
}

.mobile-gallery {
    width: 100%;
    padding: 10px;

    .image-gallery {
        // Position the bullets
        .image-gallery-bullets {
            bottom: -30px;
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            width: 80%;
            z-index: 4;

            .image-gallery-bullet {
                background-color: #666666;
                border: 1px solid #666666;
                box-shadow: none;
                height: 10px;
                width: 10px;

                &.active {
                    background-color: white;
                    border: 1px solid #666666;
                }
            }
        }

        // Hide all thumbnails - show always 1 slide at the time
        .image-gallery-thumbnails-wrapper {
            display: none;
        }

        // Make the active slide responsive
        .image-gallery-slide-wrapper {
            width: 100% !important;
            margin: 0;
        }
    }
}

.image-gallery {
    // Position the bullets
    .image-gallery-bullets {
       width: 100% !important;
    }
}

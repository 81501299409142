.made-by {
    background-color: #313131;
    color: #afafaf;
    padding: 10px;
    text-align: center;

    a {
        background-color: #313131;
        color: #afafaf;

        &:hover {
            color: white;
        }
    }
}

.md-cookie-banner {
    border: none;
    background-color: #8f6f31;
    padding: 12px;
    width: 100%;
    position: fixed;
    bottom: 0;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    @media only screen and (max-width: 767px) {
        display: block;
    }

    header {
        display: none;
    }

    p {
        padding: 0;
        margin: 5px 0;
    }

    main {
        max-width: 925px;

        a {
            color: #d4f5ff;

            &:hover {
                color: white;
            }
        }
    }

    span {
        text-decoration: underline;
        &:hover {
            cursor: pointer;
            color: black;
        }
    }

    button {
        padding: 7px 12px;
        border: 0;
        min-width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: white;

        color: black;
        box-shadow: 0 3px 11px rgba(0, 0, 0, 0.12);

        &:first-child {
            margin-right: 15px;
        }

        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        margin-left: 50px;

        @media only screen and (max-width: 767px) {
            margin-top: 10px;
        }
    }
}

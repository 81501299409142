.product-tiles {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    padding: 10px;
    width: 100%;
}

/* Filter section */
.product-tiles .product-filters {
    float: left;
    height: 1023px;
    border: 2px solid #8f6f31;
    width: 275px;
    margin: 12px;
    display: inline-block;
    vertical-align: top;
}

.product-filters-header {
    background-color: #8f6f31;
    width: 100%;
    height: 30px;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
}

.price-filter {
    height: 90px;
    border-top: 2px solid #8f6f31;
    border-bottom: 2px solid #8f6f31;
}

.material-filter {
    height: 190px;
    border-bottom: 2px solid #8f6f31;
}

.color-filter {
    height: 125px;
    border-bottom: 2px solid #8f6f31;
}

.filter-colors {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 10px;
}

.filter-colors div {
    width: 23px;
    height: 23px;
    border-radius: 100%;
    margin: 6px;
    border: 1px solid #666666;
}

.filter-checkboxes {
    padding: 5px 10px;
    display: table;
}
.check-box-with-label {
    width: 115px;
    display: inline-table;
}

.filter-label {
    font-weight: bold;
    padding: 5px 10px;
    color: #666666;
    font-size: 17px;
    letter-spacing: 0.03rem;
}
.filter-slider {
    padding: 20px 25px 0px 25px;
    color: #666666;
}

/* overwrite library --> */
.filter-slider .input-range__slider {
    background: #8f6f31;
    border: 1px solid #8f6f31;
    margin-top: -0.6rem;
}
.filter-slider .input-range__track--active {
    background: transparent;
}
.filter-slider .input-range__label-container {
    color: #666666;
    font-size: 16px;
}
.filter-slider .input-range__track {
    background: #c7c7c7;
    height: 0.18em;
}
.filter-slider .input-range__label--value {
    top: -2.2rem;
}
.filter-slider .input-range__label--min,
.filter-slider .input-range__label--max {
    left: 0;
    display: none;
}

.search-results {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.search-results h5 {
    text-align: left;
    width: 100%;
    max-width: 1200px;
    padding: 0 0.6em;
}

/* Product tiles*/

.product-tiles .product-tile {
    width: calc(25% - 12px);
    border: 2px solid #8f6f31;
    background-color: white;
    margin: 6px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.product-tiles .product-tile p {
    color: #666666;
    font-size: 18px;
    padding: 10px;
    line-height: 22px;
    height: 75px;
    overflow: hidden;

    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.product-tiles .product-tile strong {
    position: relative;
    bottom: 5px;
    left: 10px;
    color: black;
    font-size: 19px;
    font-weight: 900;
    line-height: 19px;
}

/*.product-tiles .product-tile {*/
/*    height: 342px;*/
/*}*/

.product-tiles .product-tile:hover a {
    text-decoration: none !important;
}

.product-tile .product-tile-fill {
    background-color: white;
    min-height: 118px;
    border-top: 2px solid #8f6f31;
}

.price-type-label {
    font-size: 14px;
    padding-left: 10px;
    color: #666666;
    font-style: italic;
    text-transform: lowercase;
    font-weight: normal;
}

@media only screen and (max-width: 1050px) {
    .product-tiles .product-tile {
        width: calc(33% - 10px);
        margin: 5px;
    }
    .related-items .product-tile {
        width: calc(33% - 10px);
        margin: 5px;
    }
}

@media only screen and (max-width: 767px) {
    .product-tiles .product-tile {
        width: calc(50% - 10px);
        margin: 5px;
    }
    .related-items .product-tile {
        width: calc(50% - 10px);
        margin: 5px;
    }
    .recently-viewed + .product-tiles .product-tile {
        width: calc(50% - 10px);
        margin: 5px;
    }
    .product-tiles .product-tile p {
        color: #666666;
        font-size: 16px;
    }
}

.page-content {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    /*border: 1px solid;*/
    min-height: 250px;
}

.home-grey {
    background-color: #e7e7e7;
}

.content-block {
    /*width: 1000px;*/
    /*border: 1px solid;*/
}

.content-block h2 {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #666666;
}

h2.shop-latest {
    margin: 50px;
}

/* Shop by ctageory */

.shop-by-category {
    max-width: 1200px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: 10px;
}

.category-item {
    width: calc(20% - 14px);
    margin: 5px;
    border: 2px solid #666666;

    display: flex;
    flex-direction: column;
}

.category-item img {
    width: 100%;
}

.category-item .category-item-label {
    width: 100%;
    text-align: center;
    background-color: #666666;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 6px 3px;
    transition: background-color 0.4s;
}

.category-item:hover {
    border: 2px solid #8f6f31;
    cursor: pointer;
}

.category-item:hover .category-item-label {
    background-color: #8f6f31;
    text-decoration: underline;
}

/*Shop our latest*/

.shop-latest-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    padding: 10px;
}

.shop-latest.recently-viewed {
    margin: 25px;
}

.shop-latest-container .product-tile {
    width: calc(25% - 12px);
    border: 2px solid #8f6f31;
    margin: 6px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.shop-latest-container:hover a {
    text-decoration: none !important;
}

.product-tile {
    transition: box-shadow 0.2s;
}
.product-tile:hover {
    box-shadow: #8f6f31 1px 1px 8px 3px;
}

.shop-latest-container .product-tile.see-more-items {
    background-color: #8f6f31;
    border: 2px solid #8f6f31;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.see-more-items span {
    font-size: 22px;
    text-decoration: underline;
}

.product-tile-image-container {
    width: calc(25% - 12px);
    height: 210px;
    display: inline;
}
.product-tile-image-container img {
    width: 100%;
}
.product-tile-info-container {
    background-color: white;
    border-top: 2px solid #8f6f31;
    padding: 8px;
    color: #666666;
    font-size: 18px;
}

.on-sale-button {
    position: absolute;
    width: 58px;
    height: 58px;
}

.on-sale-button img {
    margin: 10px;
}

.above-price-label {
    height: 80px;
    max-height: 80px;
    overflow: hidden;
    line-height: 19px;
}

.price-label {
    color: black;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
}
.product-tiles .price-label {
    padding: 0 10px;
}

.price-label.sold {
    font-size: 15px;
}

/* Double info blocks */
.double-info-blocks {
    margin: 100px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.double-info-blocks .info-block {
    justify-content: space-between;
    height: 325px;
    display: flex;
    color: white;
    margin: 10px;
    width: 100%;
}

.info-block h3 {
    font-weight: bold;
}
.info-block p {
    font-size: 18px;
}

.info-block-wide {
    height: 100%;
    background-color: white;
}
.info-block-narrow {
    height: 100%;
    background-color: #ffffff;
}

.zgao-gold {
    background-color: #8f6f31;
    padding: 20px;
}
.zgao-gray {
    background-color: #666666;
    padding: 20px;
}

/* About section */
.about-container {
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    max-width: 1000px;
    color: #666666;
    padding: 0 20px;
}

.about-logo {
    height: 200px;
}

.about-description {
    font-size: 26px;
    padding: 35px 0;
}

.about-slogan {
    font-size: 34px;
    padding: 15px 0;
}

.price-label .old-price {
    font-size: 19px;
    font-weight: bold;
    background-color: transparent;
    background-image: repeating-linear-gradient(163deg, transparent 0%, transparent 48%, #444444 50%, transparent 52%, transparent 100%);
    padding-right: 15px;
    color: #444444;
}

.home-shop-latest-container {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    margin: auto;
}

.home-shop-latest-container .product-tiles {
    flex-wrap: nowrap;
}

/* Tablet */
@media only screen and (max-width: 1050px) {
    .shop-latest-container .product-tile:nth-child(4) {
        display: none;
    }

    .shop-latest-container .product-tile {
        width: calc(33% - 10px);
        margin: 5px;
    }
}

.tablet-carousel {
    display: none;
}

/* special mobile gallery format for tablet */
@media only screen and (max-width: 1050px) and (min-width: 768px) {
    .carousel-wrapper {
        display: none;
    }

    .home-intro {
        text-align: center;
        color: #666666;
        padding: 0 12px 8px 12px;
    }

    .tablet-carousel {
        display: flex;
        justify-content: center;
        max-width: 730px;
        margin: auto;
    }

    .tablet-carousel .page-content {
        margin-bottom: 0;
        margin-top: 25px;
    }

    .tablet-carousel + .page-content {
        margin-top: 25px;
        margin-bottom: 30px;
    }

    .tablet-carousel .favourite-items .product-name {
        margin-top: 30px;
        padding: 20px 0;
        display: flex;
        justify-content: center;
    }

    .tablet-carousel .favourite-items .product-name a {
        text-align: center;
        text-align: -moz-center;
        max-width: 75%;
        text-decoration: underline;
        cursor: pointer;
        height: 56px;
        font-size: 19px;
        color: #666666;
    }
}

@media only screen and (min-width: 1250px) {
    .double-info-blocks {
        flex-wrap: nowrap;
        max-width: 1600px;
    }
}

@media only screen and (min-width: 1250px) and (max-width: 1550px) {
    .info-block-narrow.zgao-gray p {
        display: none;
    }
    .info-block-narrow {
        width: 100%;
    }
}

@media only screen and (max-width: 1400px) {
    .carousel-wrapper .carousel-text-container {
        display: none;
    }
}

/* Mobile */
@media only screen and (max-width: 767px) {
    .home-intro {
        color: #666666;
        padding: 0 12px 0 12px;
        text-align: center;
    }

    .page-content {
        margin-top: 12px;
    }

    .content-block h2 {
        font-size: 18px;
    }

    .shop-by-category {
        flex-wrap: wrap;
    }

    .category-item {
        width: calc(50% - 10px);
        margin: 5px;

        background-color: #666666;
    }

    .recently-viewed + .shop-latest-container .product-tile:nth-child(4) {
        display: block;
    }

    .related-items .shop-latest-container .product-tile:nth-child(4) {
        display: block;
    }

    .home-shop-latest-container .product-tiles {
        flex-wrap: wrap;
    }

    .home-shop-latest-container .shop-latest-container .product-tile:nth-child(3) {
        /*display: none;*/
    }

    .about-container {
        padding: 0 20px;
        font-size: 18px;
    }

    .price-label {
        font-size: 19px;
        line-height: 30px;
        padding: 0 10px;
    }

    .about-logo {
        height: 130px;
    }
    .about-container .about-description {
        font-size: 18px;
        text-align: justify;
    }
    .about-container .about-slogan {
        font-size: 22px;
        text-align: justify;
    }

    .favourite-items {
        padding: 0 10px;
    }

    .favourite-items .product-name {
        margin-top: 30px;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        height: 124px;
    }

    .favourite-items .product-name a {
        text-align: center;
        text-align: -moz-center;
        max-width: 75%;
        text-decoration: underline;
        cursor: pointer;

        font-size: 19px;
        color: #666666;
    }

    .mobile-info-blocks {
        padding: 0 15px;
    }
    .mobile-info-blocks .mobile-info-block {
        margin-bottom: 15px;
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        font-weight: bold;
    }
    .mobile-info-blocks .show-your-items {
        border: 2px solid #8f6f31;
        color: #8f6f31;
        margin-bottom: 35px;
    }
    .mobile-info-blocks .make-an-appointment {
        border: 2px solid #666666;
        color: #666666;
        margin-bottom: 35px;
    }
    .mobile-info-blocks .mobile-info-block span {
        text-align: center;
        display: flex;
        align-items: center;
    }
    .mobile-info-blocks .mobile-info-block span i {
        padding-right: 4px;
        font-size: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .mobile-only {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .desktop-only {
        display: none;
    }
}

* {
    font-family: "Barlow Semi Condensed", sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Barlow Semi Condensed", sans-serif;
    overflow-x: hidden !important;
}

.success {
    color: green;
}
.error {
    color: red;
}
.loading {
    color: dodgerblue;
}
.initialized {
    color: darkorange;
}

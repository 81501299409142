.product-page {
    width: 100%;
    max-width: 1200px;
    display: block;
    margin: auto;
}

.product-page h1 {
    margin-bottom: 15px;
    font-size: 28px;
    color: #666666;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    position: relative;
}
.product-page h1 .product-name-title {
    width: 100%;
    padding: 0 60px;
}

.product-page .heart-button-container {
    position: absolute;
    right: 0;
    top: -13px;
}

.product-page .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: 770px;
    border: 2px solid #8f6f31;
    margin-right: 15px;
    /* width: calc(100% - 113px); */
}

.product-gallery-container {
    display: flex;
}

.product-price-container {
    width: 200px;
}

.slide-container {
    width: 100%;
}

/* prijs bovenin */
.price-card {
    width: 190px;
    height: 125px;
    border: 2px solid #8f6f31;
    background-color: #ffffff;
    color: #8f6f31;
    padding: 10px;
    position: relative;
}

.price-card .old-price {
    position: absolute;
    font-size: 20px;
    font-weight: normal;
    top: 5px;
    right: 5px;
    background-color: transparent;
    /*text-decoration: line-through;*/
    /*background-image: repeating-linear-gradient(163deg, transparent 0%, transparent 48%, #fff 50%, transparent 52%, transparent 100%);*/
    text-decoration: line-through;
}
.price-card span {
    font-size: 19px;
}
.price-card label {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
}
/* verkoop knoppen */
.purchase {
    width: 190px;
    height: 50px;
    color: #fff;
    padding: 6px 12px;
    border: 2px solid #FF5A09;
    margin-top: 15px;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: #FF5A09;
}
.purchase span {
    padding-left: 5px;
}
.request-shipping {
    width: 190px;
    height: 82px;
    color: #8f6f31;
    padding: 6px 12px;
    border: 2px solid #8f6f31;
    margin-top: 15px;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: #fff;
}
.request-shipping span {
    padding-left: 5px;
}
.free-shipping {
    width: 190px;
    height: 82px;
    color: #8f6f31;
    padding: 6px 12px;
    border: 2px solid #8f6f31;
    margin-top: 15px;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: #fff;
}
.request-shipping span {
    padding-left: 5px;
}

.purchase:hover {
    /*transition: background-color, 0.3s, ease;*/
    /*color: white;*/
    /*background-color: #8f6f31;*/
    cursor: pointer;
    box-shadow: #FF5A09 1px 1px 4px 3px;
}

.request-shipping:hover {
    /*transition: background-color, 0.3s, ease;*/
    /*color: white;*/
    /*background-color: #8f6f31;*/
    cursor: pointer;
    box-shadow: #8f6f31 1px 1px 8px 3px;
}

.contact-question {
    width: 190px;
    height: 277px;
    color: #8f6f31;
    padding: 6px 12px;
    border: 2px solid #8f6f31;
    margin-top: 15px;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 1px;
    position: relative;
}
.sold-out-price-card {
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    padding-top: 30px;
}

.on-request .sold-out-price-card {
    padding-top: 12px;
    font-size: 20px;
}
.contact-question.is-on-request {
    height: 375px;
}
.contact-question.sold-out {
    height: 440px;
}
.contact-question.sold-out.is-on-request {
    height: 440px;
}

.contact-question .contact-buttons {
    position: absolute;
    bottom: 15px;
    left: 15px;
}
/* bounce animation */
@keyframes bounce {
    0%,
    20%,
    60%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    80% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}
.contact-question .contact-buttons i:hover {
    cursor: pointer;
    color: #666666;
    animation: bounce 1s;
}
/* Email */
.contact-question .contact-buttons i:nth-child(1) {
    font-size: 40px;
    font-weight: normal;
    padding-right: 22px;
}

/* Phone */
.contact-question .contact-buttons i:nth-child(2) {
    font-size: 30px;
    padding-right: 22px;
}
.contact-question .contact-buttons i:nth-child(2):before {
    top: -4px;
    position: relative;
}
/* WhatsApp */
.contact-question .contact-buttons i:nth-child(3) {
    font-size: 44px;
    font-weight: normal;
}
/* gallery styling */
.product-page .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative;
    width: 187px;
    border: 2px solid #8f6f31;
}

.product-page .image-gallery-thumbnails-wrapper.right {
    width: 195px;
}
.product-page .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
    width: 100%;
}
.image-gallery-thumbnail:hover {
    opacity: 0.75;
}
.image-gallery-thumbnail.active {
    border: 4px solid #eadbb1;
    opacity: 0.75;
}
/* product detail*/
.product-detail-container {
    display: flex;
    width: 1200px;
    border: 2px solid #8f6f31;
    margin-top: 25px;

    height: auto;
}
.item-details {
    width: 325px;
    color: white;
    background-color: #8f6f31;
    padding: 15px;
}
.item-details p {
    font-size: 19px;
}
.item-details a {
    color: white !important;
    text-decoration: underline;
}
.item-details label {
    font-size: 16px;
    font-weight: bold;
    width: 120px;
    letter-spacing: 0.9px;
}
.item-details span {
    font-size: 16px;
}
.item-story {
    padding: 15px;
    width: calc(100% - 325px);
}
.item-story p {
    font-size: 19px;
    color: #666666;
}

.item-story .pre-wrapped-text p{
    font-size: 1rem;
    color: #212529;
}

.item-story textarea {
    width: 100%;
    height: auto;
    max-height: 240px;
    resize: none;
    border: 0;
    outline: none;
    background-color: white;
}
.favorites-list-modal {
    .download-as-pdf {
        width: 100%;
        text-align: right;
        color: #8f6f31;
        padding-top: 5px;

        &:hover {
            cursor: pointer;
            color: #666666;
        }
    }

    ul {
        max-height: 200px;
        list-style-type: none;
        padding: 0.25em 0;
        margin: 1em 0;
        overflow: auto;
        border: 1px solid #888888;

        li {
            height: 60px;
            display: flex;
            padding: 0.25em 0;
            overflow: hidden;
            overflow-x: auto;
            white-space: pre-wrap;

            a {
                width: 80px;
                cursor: pointer;
            }
            img {
                height: 100%;
                padding-right: 0.5em;
                padding-left: 0;
            }
            label {
                max-width: 315px;
                min-width: 315px;
                cursor: pointer;
            }

            .remove-from-list {
                min-width: 40px;
                max-width: 40px;

                i {
                    width: 100%;
                    height: 100%;
                    font-size: 16px;
                    color: #666666;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &:hover {
                    cursor: pointer;
                    i {
                        color: #8f6f31;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

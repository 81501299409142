.carousel-wrapper {
    margin: 40px;
}

.carousel-image-container {
    width: 67%;
    height: 300px;
    display: inline-block;
}

.carousel-text-container {
    width: 33%;
    position: relative;
    height: 300px;
    display: inline-block;
    vertical-align: top;
    background-color: #8f6f31;
    color: white;
    margin-left: -1px;
    padding: 15px;
    font-size: 17px;
}

.carousel-wrapper .container {
    margin: 0 auto;
    padding: 40px;
    width: 80%;
    color: #333;
    background: #419be0;
}

.carousel-wrapper h3 {
    background: #7c7735;
    color: #fff;
    font-size: 36px;
    line-height: 220px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
.carousel-wrapper .variable-width .slick-slide p {
    background: blue;
    height: 500px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
}
.carousel-wrapper .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1.08);
}
.carousel-wrapper .center h3 {
    opacity: 0.8;
    transition: all 300ms ease;
}
.carousel-wrapper .content {
    padding: 20px;
    margin: auto;
    width: 90%;
}
.carousel-wrapper .slick-slide .image {
    padding: 10px;
}
.carousel-wrapper .slick-slide img {
    /*border: 5px solid #fff;*/
    display: block;
    margin: auto;
}
.carousel-wrapper .slick-slide img.slick-loading {
    border: 0;
}
.carousel-wrapper .slick-slider {
    margin: 30px auto 50px;
}
.carousel-wrapper .slick-dots {
    margin-left: 0;
}
.carousel-wrapper .slick-thumb {
    bottom: -45px;
}
.carousel-wrapper .slick-thumb li {
    width: 60px;
    height: 45px;
}
.carousel-wrapper .slick-thumb li img {
    filter: grayscale(100%);
}
.carousel-wrapper .slick-thumb li.slick-active img {
    filter: grayscale(0%);
}
@media (max-width: 768px) {
    .carousel-wrapper h3 {
        font-size: 24px;
    }
    .carousel-wrapper .center {
        margin-left: -40px;
        margin-right: -40px;
    }
    .carousel-wrapper .center .slick-center h3 {
        color: #e67e22;
        opacity: 1;
        transform: scale(1);
    }
    .carousel-wrapper .center h3 {
        opacity: 0.8;
        transform: scale(0.95);
        transition: all 300ms ease;
    }
}
.carousel-wrapper .slick-vertical .slick-slide {
    height: 300px;
}

.carousel-wrapper .slick-dots li button:before {
    font-size: 18px;
}

.slick-dots li.slick-active button:before {
    color: white;
    opacity: 1;
    border: 1px solid #666666;
    border-radius: 100px;
}
.slick-dots li button:before {
    color: #666666;
    opacity: 1;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 15px;
    padding: 0;
    cursor: pointer;
}

.carousel-arrow-left {
    position: absolute;
    left: -20px;
    top: 135px;
    font-size: 23px;
    cursor: pointer;
    color: #8f6f31;
}
.carousel-arrow-right {
    position: absolute;
    right: -20px;
    top: 135px;
    font-size: 23px;
    cursor: pointer;
    color: #8f6f31;
}

.shop-now-button {
    position: absolute;
    bottom: 25px;
    font-weight: 900;
    font-size: 22px;
    text-align: center;
    width: calc(100% - 25px);
}

.shop-now-button a {
    color: white;
    border-bottom: 2px solid white;
}

.shop-now-button a:hover {
    color: white;
    text-decoration: none;
}

.carousel-text-container a {
    color: white;
}

.carousel-text-container a:hover {
    color: white;
    /*text-decoration: none;*/
}

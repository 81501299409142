.frame {
    padding: 0;
}

.frame-top {
    border-bottom: 1px solid #8f6f31;
    border-top: 1px solid #8f6f31;
    background-color: #8f6f31;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 38px;
    color: white;
    font-size: 16px;
    padding: 6px;
    width: 100%;
}

.logo-and-menu-container {
    display: flex;
    justify-content: center;
}

.main-logo-container {
    justify-content: flex-start;
    width: 100%;
}

.main-logo {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: auto;
    max-width: 1200px;
    padding: 0 8px;
}

.main-logo img {
    width: 100%;
    max-width: 350px;
    margin: 20px 0;
    padding-left: 8px;
}

@media only screen and (max-width: 1050px) {
    .frame-top span:last-child {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .frame-top {
        display: none;
    }
}

/* Mobile header */
.mobile-header {
    display: flex;
    justify-content: space-between;
    background-color: #8f6f31;
    color: white;
    padding: 10px;
}

.mobile-header .main-logo {
    margin: 0;
    padding: 0;
}
.mobile-header .main-logo img {
    margin: 0;
    padding: 0;
    max-width: 85px;
}

.mobile-header .hamburger-container {
    width: 100%;
    max-width: 40px;
    max-height: 40px;
    padding: 12px 10px 0 0;
}

.mobile-header .hamburger-container img {
    max-width: 30px;
    max-height: 30px;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes menuSlideIn {
    from {
        right: -280px;
    }
    to {
        right: 0;
    }
}
@-webkit-keyframes menuSlideOut {
    from {
        right: 0;
    }
    to {
        right: -280px;
    }
}
/* Standard syntax */
@keyframes menuSlideIn {
    from {
        right: -280px;
    }
    to {
        right: 0;
    }
}
@keyframes menuSlideOut {
    from {
        right: 0;
    }
    to {
        right: -280px;
    }
}

@keyframes opacityIn {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.2;
    }
}

@keyframes opacityOut {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 1;
    }
}

.framework-content.fade-in {
    opacity: 0.2;
    animation-name: opacityIn;
    animation-duration: 400ms;
    pointer-events: none;
}
.framework-content.fade-out {
    opacity: 1;
    animation-name: opacityOut;
    animation-duration: 400ms;
}

.mobile-navigation-menu {
    display: none;

    z-index: 999;
    position: fixed;
    right: -280px;
    bottom: 0;
    top: 69px;
    overflow: scroll;
    width: 100%;
    max-width: 275px;
    border: 2px solid #8f6f31;
    background-color: white;
    color: black;
    box-shadow: -6px 8px 11px -5px;
}

.mobile-navigation-menu.open {
    display: block;
    right: 0;
    animation-name: menuSlideIn;
    animation-duration: 400ms;
}

.mobile-navigation-menu.closed {
    right: -280px;
    animation-name: menuSlideOut;
    animation-duration: 400ms;
}

.mobile-navigation-menu .contact-question {
    display: flex;
    height: auto;
    width: 100%;
    border: 0;
    justify-content: space-between;
}

.mobile-navigation-menu .contact-question .contact-buttons {
    position: unset;
    padding: 10px;
}

.mobile-navigation-menu .contact-question .contact-buttons i:nth-child(1) {
    font-size: 28px;
}
.mobile-navigation-menu .contact-question .contact-buttons i:nth-child(2) {
    font-size: 22px;
}
.mobile-navigation-menu .contact-question .contact-buttons i:nth-child(3) {
    font-size: 30px;
}

.mobile-navigation-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 10px 15px;
}

.mobile-navigation-menu ul li {
    text-transform: uppercase;
    font-size: 18px;
    color: #666666;
    font-weight: bold;
    padding: 2px 0;
}
.mobile-navigation-menu ul li p {
    margin: 0;
    padding: 0;
}

.mobile-navigation-menu ul li p i.fa {
    margin: 0;
    padding: 0;
    width: 15px;
    color: #8f6f31;
}

.mobile-navigation-menu ul li .submenu-item {
    padding-left: 15px;
    font-size: 17px;
    text-transform: lowercase;
    font-weight: normal;
}

.mobile-navigation-menu ul li .submenu-item div {
    padding: 2px 0;
}

.mobile-navigation-menu ul li a {
    color: #666666;
}

.search-input-container--mobile {
    margin: 1em;
    width: 100%;
    max-height: 40px;
    display: flex;
    align-items: center;
}

.search-input-container--mobile input {
    width: 200px;
    padding-left: 8px;
    outline: none;
    height: 40px;
}

.search-input-container--mobile button {
    width: 40px;
    height: 40px;

    border: 1px solid #a9a9a9;
    border-left-width: 0;

    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    box-shadow: none;
    outline: none;

    background-color: #e7e7e7;
}

@media only screen and (max-width: 1024px) {
    .desktop-menu {
        display: none;
    }
    .frame-top {
        display: none;
    }
    .mobile-menu {
        display: block;
    }
}
@media only screen and (min-width: 1025px) {
    .desktop-menu {
        display: block;
    }
    .mobile-menu {
        display: none;
    }
}

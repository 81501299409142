.search-input-container {
    display: flex;
    max-height: 40px;
    top: 28px;
    right: 42px;
    position: relative;

    input {
        width: 300px;
        padding-left: 8px;
        outline: none;
    }

    button {
        width: 40px;
        border: 1px solid #a9a9a9;
        border-left-width: 0;

        -webkit-appearance: none;
        appearance: none;
        -moz-appearance: none;
        box-shadow: none;
        outline: none;

        background-color: #e7e7e7;
    }
}

.favorites-list {
    max-height: 40px;
    top: 28px;
    right: 28px;
    position: relative;
    cursor: pointer;

    .show-list-button {
        width: 40px;
        height: 40px;
        //border: 1px solid #a9a9a9;
        box-shadow: none;
        outline: none;
        cursor: pointer;

        //background-color: #e7e7e7;

        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 32px;
            cursor: pointer;
            color: #8f6f31;
        }
        label {
            position: absolute;
            color: white;
            margin: auto;
            text-align: center;
            width: 32px;
            cursor: pointer;
            font-size: 14px;
            line-height: 30px;
        }

        &:hover {
            i {
                color: #666666;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .search-input-container {
        display: none;
    }
    .favorites-list {
        top: 7px;

        .show-list-button {
            i {
                color: white;
            }
            label {
                color: #8f6f31;
            }
            &:hover {
                i {
                    color: #dedede;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .accordeon-container {
        padding: 15px;

        .accordeon-frame {
            border: 2px solid #8f6f31;
            margin-bottom: 15px;

            .accordeon-item {
                background-color: white;
                color: #8f6f31;

                padding: 5px 10px;

                &.active {
                    background-color: #8f6f31;
                    color: white;
                }
            }

            .mobile-customer-info {
                display: none;
                padding: 10px;
                color: #666666;
                text-align: justify;

                p {
                    font-weight: bold;
                }

                &.active {
                    display: block;
                }
            }
        }
    }
}
